@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 100;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

body {
  background: #fff;
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 100;
  color: #333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 10px 50px 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  background: rgba(36, 36, 36, 0.7);
}

#headerwrap {
  z-index: 5;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 15px 0;
}

@media only screen and (max-width: 1200px) {
  #header {
    padding: 15px 10px;
  }
}

#header .flxL {
  width: auto;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID {
    width: 50%;
    margin: 0 auto 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    right: 0;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#header .flxL #siteID a {
  text-align: left;
  display: block;
}

#header .flxL #siteID img {
  width: 100% !important;
  max-width: calc(320px / 3);
}

@media only screen and (max-width: 1000px) {
  #header .flxL #siteID img {
    max-width: calc(320px / 3);
  }
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID img {
    max-width: calc(320px / 4);
  }
}

#header .flxR {
  width: 85%;
}

#header .flxR .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .flxR .wrap #hednav {
  width: 100%;
}

#header .flxR .wrap #hednav ul li a {
  display: block;
}

.navwrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.navwrap #hed_info {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #f1f1f1;
}

.navwrap #hed_info .hoverbtn {
  border-radius: 10px;
  background: #242424;
  transition: 2s;
}

.navwrap #hed_info .hoverbtn:hover {
  background: #730404;
}

.navwrap #hed_info .hoverbtn p a {
  opacity: 1;
  display: block;
  color: #f1f1f1;
}

.navwrap #hed_info .hoverbtn span {
  display: none;
}

@media only screen and (max-width: 834px) {
  .navwrap #hed_info .hoverbtn span {
    display: block;
  }
}

.navwrap #hed_info .store1 {
  margin-right: 10px;
}

.navwrap #hed_info .store1 p {
  padding: 10px 20px;
}

@media only screen and (max-width: 834px) {
  .navwrap #hed_info .store1 p {
    padding: 0;
  }
  .navwrap #hed_info .store1 p a {
    padding: 10px 20px;
  }
}

.navwrap #hed_info .store1 p i {
  vertical-align: middle;
  margin-right: 7px;
}

.navwrap #hed_info .store2 a {
  padding: 10px 20px;
}

.navwrap #hed_info .store2 a i {
  vertical-align: middle;
}

.navwrap #nav_global ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navwrap #nav_global ul li {
  position: relative;
  flex-grow: 1;
}

.navwrap #nav_global ul li a {
  font-family: "Times New Roman";
  display: block;
  line-height: 1;
  color: #f1f1f1 !important;
  padding: 20px;
  font-size: 16px;
}

@media only screen and (max-width: 1000px) {
  .navwrap #nav_global ul li a {
    padding: 10px 10px;
  }
}

.navwrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.navwrap #nav_global ul li a span {
  margin-top: 10px;
  font-size: 11px;
  display: block;
}

.navwrap #nav_global ul li ul {
  height: auto;
  width: 100%;
  position: absolute;
  display: block;
  background: rgba(27, 73, 150, 0.8);
}

.navwrap #nav_global ul li ul li {
  height: auto;
}

.navwrap #nav_global ul li ul li a {
  height: auto;
  padding: 30px 10px;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    background: #f4f7f6;
    border-right: 1px solid #E6EAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 68px;
    width: 68px;
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #000;
    line-height: 1;
    transition: 1s;
  }
  .global-menu-btn:hover {
    color: #fff;
    background: rgba(115, 4, 4, 0.7);
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    width: 68px;
    height: 67px;
    background: #f4f7f6;
    border-left: 1px solid #E6EAEA;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #000;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: #eef2f2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    border-bottom: 1px solid #E6EAEA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5%;
  }
  #hednav .wrap .navwrap #hed_info .store1 {
    margin-bottom: 20px !important;
  }
  #hednav .wrap .navwrap #hed_info .store1 img {
    width: 100% !important;
    max-width: 330px;
  }
  #hednav .wrap .navwrap #hed_info .store2 img {
    width: 100% !important;
    max-width: 330px;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 100%;
    margin: 0;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    transition: 1s;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 700;
    margin-right: 7px;
    font-size: 1.5rem !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #E6EAEA;
  }
  #hednav .wrap .navwrap #nav_global ul li ul {
    display: block !important;
    position: static;
    height: auto !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a {
    color: #fff !important;
  }
  #hednav .wrap .navwrap #nav_global ul li ul li a:before {
    display: none;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    line-height: 1;
    height: 100%;
    padding: 24px 36px;
    font-size: 16px;
    font-weight: bold;
    color: #202121 !important;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global ul li a:hover {
    background: #ccc;
  }
  #hednav .wrap .navwrap #nav_global ul li a span {
    display: none;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

@keyframes mainscl {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes mainscl2 {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

#mainArea {
  position: relative;
  width: 100%;
}

#mainArea > img {
  width: 100% !important;
  height: auto;
}

#mainArea .mainlogo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  #mainArea .mainlogo {
    max-width: calc(320px / 2);
    margin: auto;
  }
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  position: relative;
  z-index: 1;
}

#local-keyvisual h1 {
  display: inline-block;
  z-index: 1;
  position: relative;
  color: #fff;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 100;
  padding: 240px 0;
  line-height: 1.7em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual h1 {
    font-size: 30px;
    padding: 100px 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 25px;
  }
}

#local-keyvisual h1 span {
  display: block;
  font-size: 0.8em;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin: 50px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

div.translate {
  color: #767676;
  line-height: 1.4;
}

span.translate + span {
  font-size: 16px;
  color: #767676;
  display: block;
  font-weight: normal;
  line-height: 1.4;
}

#builingual {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 2;
  padding: 5px 10px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important;
}

@media only screen and (max-width: 834px) {
  #builingual {
    bottom: 30px;
  }
}

.language {
  color: #333 !important;
  float: none !important;
  padding: 0 !important;
}

.switch2 {
  float: none !important;
  width: 33px;
  height: auto;
  margin-left: 5px !important;
  display: block !important;
}

.switch2 input[type="checkbox"] {
  display: none;
}

.switch2 label {
  display: block;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(136, 136, 136, 0.4);
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  height: 100%;
  transition: .3s;
}

.switch2 label span {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 5px;
}

div#builingual div.language select {
  margin: 0 !important;
  margin-left: 5px !important;
  padding: 4px 5px !important;
}

/* ON */
.switch2 label span:after {
  content: "OFF";
  font-size: 10px;
  font-weight: 500;
  color: #aaa;
}

.switch2 #cmn-toggle-1:checked + label {
  background-color: #ff7f00;
  box-shadow: none;
}

.switch2 #cmn-toggle-1:checked + label span:after {
  content: "ON";
  color: #fff;
}

.bili_name dl:nth-of-type(2) dd div,
.bili_name dl:last-of-type dd div {
  display: block !important;
}

.bili_name dl:nth-of-type(2) dd .translate,
.bili_name dl:last-of-type dd .translate {
  display: none !important;
}

.translate {
  color: rgba(51, 51, 51, 0.8);
  font-size: 0.8em;
  display: block;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer2 {
  position: relative;
  margin-bottom: 80px;
}

@media only screen and (max-width: 640px) {
  #footer2 {
    margin-bottom: 50px;
  }
}

#footer2 .inner {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  #footer2 .inner {
    display: block;
    text-align: center;
  }
}

#footer2 .inner .flxL {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

#footer2 .inner .flxL #siteID {
  margin-bottom: 15px;
}

#footer2 .inner .flxL #siteID a {
  opacity: 1;
  color: #222;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner .flxL #siteID a {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner .flxL #siteID a {
    font-size: 35px;
  }
}

#footer2 .inner .flxL p {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #333;
}

#footer2 .inner .flxL dl {
  margin-top: 15px;
}

#footer2 .inner .flxL dl dt {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

#footer2 .inner .flxL dl dd {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #333;
}

#footer2 .inner .flxL .fwrap {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 1000px) {
  #footer2 .inner .flxL .fwrap {
    display: block;
  }
}

#footer2 .inner .flxL .fwrap .tel2 {
  color: #222;
  font-weight: 400;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  #footer2 .inner .flxL .fwrap .tel2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  #footer2 .inner .flxL .fwrap .tel2 {
    font-size: 35px;
  }
}

#footer2 .inner .flxL .fwrap .tel2 span {
  margin-right: 10px;
  font-size: 0.5em;
}

#footer2 .inner .flxR {
  width: 58%;
}

@media only screen and (max-width: 834px) {
  #footer2 .inner .flxR {
    width: 100%;
  }
}

#footer {
  background: #242424;
  padding: 40px 0;
}

#footer .inner #nav_footer {
  max-width: 960px;
  margin: 0 auto 30px;
}

@media only screen and (max-width: 834px) {
  #footer .inner #nav_footer {
    display: none;
  }
}

#footer .inner #nav_footer ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#footer .inner #nav_footer ul li a {
  color: #fff;
}

#footer .inner #siteID a {
  text-align: center;
  display: block;
}

#footer .inner #siteID img {
  width: 100% !important;
  max-width: calc(320px / 2);
}

@media only screen and (max-width: 1000px) {
  #footer .inner #siteID img {
    max-width: calc(320px / 2);
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner #siteID img {
    max-width: calc(320px / 2);
  }
}

#copy {
  background: #242424;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 40px;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 90px;
  height: 90px;
  width: 90px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  background: #fff;
}

.progress-wrap::after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: '\f062';
  font-weight: 700;
  text-align: center;
  font-size: 30px;
  color: #0b1f56;
  left: 0;
  top: 0;
  height: 90px;
  width: 90px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  content: '\f062';
  font-weight: 700;
  text-align: center;
  font-size: 30px;
  color: #0b1f56;
  opacity: 0;
  left: 0;
  top: 0;
  height: 90px;
  width: 90px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #0b1f56;
  stroke-width: 5;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #1f2029;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #1f2029;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn2 a {
  margin: 0 auto 10px;
  max-width: 300px;
  border-bottom: 2px solid #000;
  line-height: 1;
  display: block;
  font-weight: 100;
  color: #000 !important;
  padding: 10px;
  font-size: 16px;
  font-size: 1vw;
}

@media only screen and (min-width: 1600px) {
  .btn2 a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 834px) {
  .btn2 a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .btn2 a {
    max-width: 100%;
    width: 100%;
    padding: 15px 10px;
  }
}

.btn2 a:hover {
  opacity: 1;
  -webkit-animation: 0;
  animation: zoom 0;
  color: #730404 !important;
  border-bottom: 2px solid #730404;
}

.btn a {
  max-width: 200px;
  border: 1px solid #f1f1f1;
  line-height: 1;
  display: block;
  font-weight: 100;
  color: #f1f1f1 !important;
  padding: 15px 10px;
  font-size: 16px;
  font-size: 1vw;
}

@media only screen and (min-width: 1600px) {
  .btn a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 834px) {
  .btn a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .btn a {
    max-width: 100%;
    width: 100%;
    padding: 15px 10px;
  }
}

.btn a:hover {
  opacity: 1;
  -webkit-animation: 0;
  animation: zoom 0;
  color: #730404 !important;
  border: 1px solid #730404;
}

.cont_1 {
  background: #242424;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 {
    display: block;
    position: relative;
    overflow: hidden;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    display: block;
    padding: 50px 10px;
    margin: 0 auto 50px auto !important;
  }
}

.cont_1 .flxL {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
  width: 35%;
  margin: auto;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 .flxL {
    width: 100%;
    padding: 50px;
    z-index: 1;
    position: relative;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_1 .flxL .txt {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.cont_1 .flxL .txt div {
  color: #f1f1f1;
}

.cont_1 .flxR {
  width: 50%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 .flxR {
    width: 75%;
    position: absolute;
    top: 0;
    right: -25%;
    bottom: 0;
    z-index: 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxR {
    width: 100%;
  }
}

.cont_1 .flxR .img {
  height: 100%;
}

.cont_1 .flxR .img img {
  width: 100% !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1 .flxR .img img {
    width: 100% !important;
  }
}

.cont_1 .flxR .img:after {
  display: none;
}

.cont_2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_2 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    display: block;
    margin: 0 auto 50px auto !important;
  }
}

.cont_2 a:hover .img > img {
  transform: scale(1.2);
}

.cont_2 a .img img {
  transition: transform 0.4s;
}

.cont_2 .box {
  box-shadow: 0 0px 8px rgba(51, 51, 51, 0.2);
  overflow: hidden;
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .cont_2 .box {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_2 .box td {
  border: 0 !important;
  padding: 0 !important;
}

.cont_2 .box td div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cont_2 .box td div img {
  width: auto !important;
}

.cont_3 {
  background: #242424;
  padding: 80px 0;
  margin: 160px auto 80px auto;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_3 {
    margin: 80px auto;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 {
    margin: 50px auto;
    padding: 50px 0;
  }
}

.cont_3 .inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_3 .inner {
    display: block;
  }
  .cont_3 .inner div {
    text-align: center !important;
  }
  .cont_3 .inner .btn a {
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .inner {
    display: block;
  }
  .cont_3 .inner div {
    text-align: left !important;
  }
  .cont_3 .inner .btn a {
    margin: 0 !important;
    text-align: center !important;
  }
}

.cont_3 .inner .flxL {
  box-shadow: 0 0px 8px rgba(51, 51, 51, 0.2);
  position: absolute;
  top: -160px;
  left: 0;
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner .flxL {
    left: 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxL {
    position: static;
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_3 .inner .flxL .img img {
  width: 100% !important;
}

.cont_3 .inner .flxR {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
  width: 35%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxR {
    width: 100%;
  }
}

.cont_3 .inner .flxR .txt {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.cont_3 .inner .flxR .txt div {
  color: #f1f1f1;
}

.owl-carousel {
  display: block !important;
}

.media {
  max-width: 1200px;
  margin: 0 auto 80px;
}

@media only screen and (max-width: 1200px) {
  .media {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 640px) {
  .media {
    margin: 0 auto 50px;
  }
}

.media .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.63934%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media .sns_list {
    margin-left: -1.63934%;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.media .sns_list > div {
  border-bottom: 0;
  box-shadow: 0 0px 8px rgba(51, 51, 51, 0.2);
  width: 18.36066%;
  height: 18.36066%;
  margin-left: 1.63934%;
  margin-bottom: 1.63934%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media .sns_list > div {
    width: 18.36066%;
    margin-left: 1.63934%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list > div {
    width: 48%;
    margin-left: 0;
  }
}

.media .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

.media .sns_list > div .sns_photo a {
  display: block;
  position: relative;
}

.media .sns_list > div .sns_photo a:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.media .sns_list > div .sns_photo img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.media .sns_list > div .sns_text {
  display: none;
}

.cont_4 {
  padding: 80px 0;
  background: rgba(240, 240, 240, 0.5);
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    padding: 50px 0;
    margin: 0 0 50px 0 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_4 .inner {
    padding: 0 10px;
  }
}

.news {
  height: 400px;
}

.news .blog_list {
  padding-right: 10px;
  height: 400px;
  overflow-y: scroll;
  border: 0;
  display: block !important;
}

.news .blog_list li {
  border-bottom: 1px dashed #ccc;
  padding: 20px 0;
}

.news .blog_list li .blog_photo {
  display: none;
}

.news .blog_list li .blog_text {
  padding: 0;
  font-size: 14px;
  width: 100%;
  display: block;
}

.news .blog_list li .blog_text div.detail {
  font-weight: 500;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  margin-top: 15px;
}

.news .blog_list li .blog_text .textwrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.news .blog_list li .blog_text .textwrap .textbox .blog_date {
  line-height: 1;
  color: #f1f1f1;
  font-size: 14px;
  border-radius: 10px;
  background: #242424;
  padding: 13px 10px;
  margin-right: 10px;
}

.news .blog_list li .blog_text .textwrap .textbox h3 {
  line-height: 1;
  margin-bottom: 0;
}

.news .blog_list li .blog_text .textwrap .textbox h3 a {
  font-weight: 500;
  font-size: 18px;
  color: #222;
}

.news .blog_list li .blog_text .textwrap .textbox h3 a span {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.news .blog_list li .blog_text ul {
  display: none;
}

/* title */
h2.mail.title {
  display: none !important;
}

.midashi h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #f1f1f1;
  font-weight: 200;
  line-height: 1em;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 35px;
  }
}

.midashi2 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #222;
  font-weight: 200;
  line-height: 1.7em;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 35px;
  }
}

.midashi3 h2 {
  font-family: 'Cormorant Infant', serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  color: #fff;
  font-weight: 100;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

.midashi3 h2:after {
  flex-grow: 1;
  content: "";
  display: block;
  border-top: solid 1px #f6ed13;
  width: auto;
  height: 1px;
  z-index: 0;
}

.midashi3 span {
  display: inline-block;
  z-index: 1;
  position: relative;
  padding: 0 15px 0 0;
}

.midashi3 br {
  display: none !important;
}

.midashi4 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #000;
  font-weight: 200;
  line-height: 1.7em;
  font-size: 20px;
  font-size: 1.25vw;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi5 {
  display: block;
  width: 100%;
}

.midashi5 h3 {
  border-left: 5px solid #000;
  padding-left: 10px;
  font-family: 'Cormorant Infant', serif;
  color: #111;
  font-weight: 200;
  line-height: 1.4em;
  font-size: 18px;
  font-size: 1.125vw;
}

@media only screen and (min-width: 1600px) {
  .midashi5 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .midashi5 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .continfo img {
    width: 100% !important;
  }
}

.comp01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 834px) {
  .comp01 {
    display: block;
  }
}

.comp01 .flxL {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .comp01 .flxL {
    width: 100%;
  }
}

.comp01 .flxR {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .comp01 .flxR {
    width: 100%;
  }
}

.waku {
  padding: 40px;
  background: #fff;
  border: double 7px #311a08;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.cont_5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .cont_5 {
    flex-direction: column-reverse;
  }
}

.cont_5:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .cont_5:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.cont_5:nth-child(odd) .flxR {
  width: 57%;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .cont_5:nth-child(odd) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.cont_5 .flxL {
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .cont_5 .flxL {
    width: 100%;
  }
}

.cont_5 .flxR {
  width: 57%;
}

@media only screen and (max-width: 640px) {
  .cont_5 .flxR {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.cont_5 img {
  width: 100% !important;
}

.cont_6 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .cont_6 {
    flex-direction: column-reverse;
  }
}

.cont_6:nth-child(odd) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .cont_6:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.cont_6:nth-child(odd) .flxR {
  width: 57%;
  margin-left: 0;
}

@media only screen and (max-width: 834px) {
  .cont_6:nth-child(odd) .flxR {
    width: 100%;
    margin-right: 0;
  }
}

.cont_6 .flxL {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .cont_6 .flxL {
    width: 100%;
  }
}

.cont_6 .flxR {
  width: 57%;
}

@media only screen and (max-width: 834px) {
  .cont_6 .flxR {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.cont_6 img {
  width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .comp dl {
    display: block !important;
  }
}

.comp dl dt {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 1200px) {
  .comp dl dt {
    width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .comp dl dt {
    width: 100% !important;
  }
}

.comp dl dd {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl a {
  color: #b2b2b2;
}

.kokusai01 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai01 {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.kokusai01 .box {
  position: relative;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai01 .box {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 .box {
    width: 48%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai01 .box:nth-child(3) {
    width: 100%;
  }
}

.kokusai01 .box img {
  width: 100% !important;
}

.kokusai01 .box > div {
  z-index: 1;
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kokusai02 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.kokusai02 .box {
  margin-bottom: 20px;
  width: 31%;
}

.kokusai02 .box article {
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.kokusai02 .box article h3 {
  min-width: 35px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  vertical-align: middle;
  background-color: #DBDBDB;
  color: #333333;
}

.kokusai03 {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.69492%;
  margin-bottom: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai03 {
    margin-left: -2.1097%;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai03 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.kokusai03 .box {
  position: relative;
  width: 18.30508%;
  height: 18.30508%;
  margin-left: 1.69492%;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .kokusai03 .box {
    width: 31.2236%;
    margin-left: 2.1097%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .kokusai03 .box {
    width: 48%;
    margin-left: 0;
  }
}

.kokusai03 .box img {
  width: 100% !important;
}

.kokusai03 .box article {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.kokusai03 .box article h3 {
  min-width: 35px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  vertical-align: middle;
  background-color: #DBDBDB;
  color: #333333;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.waku2 {
  justify-content: space-between;
}

.waku2 .box {
  width: 48%;
  padding: 40px;
  background: whitesmoke;
  border: double 7px #1b4996;
}

@media only screen and (max-width: 640px) {
  .waku2 .box {
    width: 100%;
    padding: 20px;
  }
}

.waku2 .box img {
  margin-bottom: 20px;
}

.waku2 .box h3 {
  margin-bottom: 15px;
  color: #000;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .waku2 .box h3 {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .waku2 .box h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .waku2 .box h3 {
    font-size: 20px;
  }
}

.member01 {
  padding: 5%;
  background: #f7f7f7;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 640px) {
  .comp2 dl {
    text-align: center;
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #000;
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #1b4996 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #1b4996;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #1b4996;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -3px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #376eca solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #376eca;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -6px;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.fadein {
  opacity: 0;
  transition: 2s;
}

@media only screen and (max-width: 834px) {
  .fadein {
    opacity: 1;
    transform: translate(0);
  }
}

.fadein.scrollin {
  opacity: 1;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-bottom {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-bottom2 {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 2000ms;
}

@media only screen and (max-width: 834px) {
  .slide-bottom2 {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-bottom2.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-bottom3 {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 2500ms;
}

@media only screen and (max-width: 834px) {
  .slide-bottom3 {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-bottom3.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-top {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-right {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

@media only screen and (max-width: 834px) {
  .slide-left {
    opacity: 1;
    transform: translate(0);
  }
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}
